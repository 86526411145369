const theme = {
  colors: {
    text: "#FFF",
    background: "#1b2125",
    primary: "#ff2d87",
    secondary: "#6e00ff",
    accent: "#ba1524",
    light: "#fbfbfb",
    muted: "#1b2125",
  },
  fonts: {
    body: "Open Sans, sans-serif",
    heading: "Open Sans, sans-serif",
    monospace: "Melo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  text: {
    paragraph: {
      marginTop: "1em",
      marginBottom: "1em",
    },
    social: {
      alignSelf: "flex-end",
      padding: "1em",
      margin: "0em",
    },
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      color: 'secondary',
    },
    footer: {
      color: 'white',
    },
  },
  buttons: {
    primary: {
      margin: '2',
      backgroundColor: "primary",
      ":hover, :focus": {
        backgroundColor: "secondary",
      },
    },
  },
  links: {
    social: {
      color: "white",
      ":hover, :focus": {
        color: "secondary",
      },
    },
    nav: {
      color: "primary",
      ":hover, :focus": {
        color: "secondary",
      },
    },
  },
  forms: {
    label: {
      fontSize: 1,
      fontWeight: 'bold',
      fontFamily: 'body',
    },
    input: {
      color: "text",
      borderColor: 'gray',
      fontFamily: 'body',
      width: '300px',
      margin: '1',
      padding: '1',
      '&:focus': {
        borderColor: 'primary',
        boxShadow: t => `0 0 0 2px ${t.colors.primary}`,
        outline: 'none',
      },
      ':-webkit-autofill': {
        '-webkit-text-fill-color': 'yellow !important',
      }
    },
    select: {
      color: "text",
      borderColor: 'gray',
      fontFamily: 'body',
      margin: '1',
      padding: '1',
      '&:focus': {
        borderColor: 'primary',
        boxShadow: t => `0 0 0 2px ${t.colors.primary}`,
        outline: 'none',
      },
      ':-webkit-autofill': {
        '-webkit-text-fill-color': 'yellow !important',
      }
    },
    textarea: {
      color: "text",
      borderColor: 'gray',
      fontFamily: 'body',
      height: '150px',
      width: '400px',
      margin: '1',
      padding: '1',
      '&:focus': {
        borderColor: 'primary',
        boxShadow: t => `0 0 0 2px ${t.colors.primary}`,
        outline: 'none',
      },
      ':-webkit-autofill': {
        '-webkit-text-fill-color': 'yellow !important',
      }
    },
    slider: {
      bg: 'muted',
    },
  },
  styles: {
    h1: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      marginTop: 0,
      marginBottom: 3,
    },
    a: {
      color: "secondary",
      ":hover, :focus": {
        color: "primary",
      },
    },
    root: {
      margin: "0px",
      padding: "0px",
    },
  },
};

export default theme;
